import decode from 'entity-decode';
import prettyNum, {PRECISION_SETTING, ROUNDING_MODE} from 'pretty-num';


/**
 *
 * @param {string|number} value
 * @param {number} precision
 * @param {PRECISION_SETTING} [precisionSetting]
 * @return {string}
 */
export function prettyNumber(value, precision, precisionSetting) {
    return decode(prettyNum(value, {
        precision,
        precisionSetting,
        separateOneDigit: false,
        thousandsSeparator: '&#x202F;',
    }));
}

/**
 * @param {string|number} value
 * @return {string}
 */
export function pretty(value) {
    if (!value && value !== 0) {
        return '';
    }
    return prettyNumber(value, 2, PRECISION_SETTING.FIXED);
}


export function prettyRound(value) {
    return prettyNumber(value, 0, PRECISION_SETTING.FIXED);
}
